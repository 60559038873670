import { useCallback, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { SectionHeading } from "@components/headings/section";
import { ImgixNextBackground } from "@components/imgixNextBackground";
import { tagular } from "@cohesion/tagular";
import { LP_SHOP_URL } from "@constants/lpUrl";

const LP_SHOP_ALL_BOOKS_URL = `${LP_SHOP_URL}/collections/all?sort_by=created-descending&utm_source=lonelyplanet&utm_campaign=homepage&utm_content=homepagemodule`;

export function GuideBooks() {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      tagular("product_view", {
        product: {
          location: "HOMEPAGE MODULE_SHOP",
          text: "SHOP NOW",
          position: 0,
          brand: "shop",
        },
      });
    }
  }, [inView]);

  const handleAllBooksClick = useCallback(text => {
    tagular("product_click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: LP_SHOP_ALL_BOOKS_URL,
      product: {
        location: "HOMEPAGE MODULE_SHOP",
        text,
        position: 0,
        brand: "shop",
      },
    });
  }, []);

  return (
    <div
      ref={ref}
      className="bg-blue-100 h-auto w-full block lg:flex lg:h-[625px] xl:h-[720px] overflow-x-hidden"
    >
      <a
        href={LP_SHOP_ALL_BOOKS_URL}
        target="_blank"
        className="lg:hidden"
        rel="noopener noreferrer"
        onClick={() => handleAllBooksClick("IMAGE")}
      >
        <ImgixNextBackground
          imageClasses="object-bottom bg-blue-100 lg:hidden"
          wrapperClasses="w-full h-60 lg:hidden"
          src="https://lonelyplanetstatic.imgix.net/marketing/2025/shop/mobile_books_transparent_portrait_v7_3x.png"
          alt="Shop our books"
        />
      </a>

      <ImgixNextBackground
        imageClasses="hidden bg-blue-100 lg:block lg:ml-36 xl:ml-10 2xl:ml-0"
        wrapperClasses="w-full h-full relative"
        src="https://lonelyplanetstatic.imgix.net/marketing/2025/shop/desktop_books_transparent_landscape_2x.png"
        alt="Shop our books"
      >
        <div className="container relative h-full pb-16 lg:pt-16">
          <div className="w-full lg:w-[470px] xl:w-128">
            <p className="text-sm font-bold uppercase">shop our books</p>
            <SectionHeading className="pt-2 text-blue">
              Beyond the beaten path
            </SectionHeading>
            <p className="pt-4 pb-8 leading-relaxed">
              Some travelers count countries, others count stories. We’ve spent
              decades collecting both. ���Ĵ�ý’s books cover the globe’s
              most extraordinary places and experiences.
            </p>
            <a
              href={LP_SHOP_ALL_BOOKS_URL}
              className="inline-block btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleAllBooksClick("SHOP NOW")}
            >
              Shop now
            </a>
          </div>
        </div>
        <a
          href={LP_SHOP_ALL_BOOKS_URL}
          target="_blank"
          className="absolute top-0 right-0 hidden w-3/4 h-full lg:block"
          rel="noopener noreferrer"
          onClick={() => handleAllBooksClick("IMAGE")}
        >
          <span className="sr-only">Shop our books</span>
        </a>
      </ImgixNextBackground>
    </div>
  );
}
